import { createSlice } from '@reduxjs/toolkit'
import { auth, database } from 'utils/firebase'
import axios from 'axios'
import moment from 'moment'
import {
  getAddressWithLatLng,
  getIP,
  getLatLngWithIP,
} from '../helper/locationHelpers'
// ------------------------------------
// State
// ------------------------------------

const initialState = {
  checked: false,
  sessionKey: null,
  loggedIn: false,
  me: {},
  startChat: false,
  openConversationNode: null,
  queued: null,
  messages: [],
  doctorProfile: {},
  socialInfo: {},
  newUser: false,
  sqlData: null,
  thirdPartyData: null,
  sqlThirdPartyData:null,
  thirdPartyHandled: false,
  issueInfo: null,
  userPatientData: null,
  queueTime: null,
  openConversations: null,
  skipHealthCard: false,
  isThirdPartyUser: false,
  selectedClinic: null,
  clinics: null,
  stripeCustomer: null,
  pharmaInfo: null,
  campaignSource: null,
  closeChat:false,
  showDownloadApp: false,
  source: null,
  utmParams: null,
  userIssue: null,
  referredBy: null,
  locationInfo: null,
    storeUsersConsent: null,
}

let userProfileRef = null

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me || state.me,
      loggedIn: action.payload.loggedIn,
      checked: action.payload.checked,
    }),
    resetState: (state, action) => ({
      ...state,
      loggedIn: false,
      startChat: false,
      openConversationNode: null,
      queued: null,
      messages: [],
      doctorProfile: {},
      socialInfo: {},
      newUser: false,
      sqlData: null,
      sqlThirdPartyData:null,
      thirdPartyHandled: false,
      userPatientData: null,
      queueTime: null,
      openConversations: null,
      skipHealthCard: false,
      isThirdPartyUser: false,
      selectedClinic: null,
      clinics: null,
      stripeCustomer: null,
      isEmailSignIn: false,
      userIssue: null,
      referredBy: null,
        storeUsersConsent: null
    }),


    setSessionKey: (state, action) => ({
      ...state,
      sessionKey: action.payload,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
    }),
    setChecked: (state, action) => ({
      ...state,
      checked: action.payload,
    }),
    setSource: (state, action) => ({
      ...state,
      source: action.payload,
    }),
    setUtmParams: (state, action) => ({
      ...state,
      utmParams: action.payload,
    }),
    setReferredBy: (state, action) => ({
      ...state,
      referredBy: action.payload,
    }),

    setSocialInfo: (state, action) => ({
      ...state,
      socialInfo: action.payload.socialInfo,
    }),
    setNewUser: (state, action) => ({
      ...state,
      newUser: action.payload,
    }),
    setUserIssue: (state, action) => ({
      ...state,
      userIssue: action.payload,
    }),
    setLocationInfo: (state, action) => ({
      ...state,
      locationInfo: action.payload,
    }),
      setStoreUsersConsent: (state, action) => ({
          ...state,
          storeUsersConsent: action.payload,
      }),

  },
})

// ------------------------------------
// Actions
// -----------------------------------


export const setSocialInfo = (payload) => async (dispatch, getState) => {
  try{
    const source = getState()?.app?.source;
    const utmParams = getState()?.app?.utmParams;
    const referredBy = getState()?.app?.referredBy;
    const locationInfo = getState()?.app?.locationInfo;
    const {info = {}, firstName, lastName, selectedDate, uid, selectedGender, email, phoneNumber} = payload || {};
    const {  family_name, given_name, picture } = info
    const socialEmail = info.email
    const fName = given_name || firstName || "";
    const lName = family_name || lastName || "";
    const data = { ...info, firstName: fName, lastName: lName}
    dispatch(slice.actions.setSocialInfo({socialInfo: data}));
    const date = new Date()
    let offset = date.getTimezoneOffset()
    offset = offset || 0
    offset = (offset * -1) / 60
    const userData = await database.ref(`users/${uid}`).once('value');
    const userJSON = userData.exists()?userData.toJSON():{}
    let currentDOB = null;
    if (selectedDate){
      currentDOB = moment(selectedDate).format('MMMM DD YYYY')
    }
    const DOB = userJSON.DOB || currentDOB || null
    let name = userJSON.name || userJSON.userName || null;
    if(!name && (fName || lName)) name = `${fName} ${lName}`;
    let userName = userJSON.userName || userJSON.name || null;
    if(!userName && (fName || lName)) userName = `${fName} ${lName}`;
    let update = {};
    if(DOB) update[`users/${uid}/DOB`] = DOB;
    if(userName) update[`users/${uid}/userName`] = userName;
    if(name) update[`users/${uid}/name`] = name;
    update[`users/${uid}/phone_number`] = userJSON?.phone_number || phoneNumber || null;
    update[`users/${uid}/gender`] = userJSON?.gender || selectedGender || null;
    update[`users/${uid}/email`] = userJSON?.email || socialEmail || email || null;
    update[`users/${uid}/ban`] = userJSON?.ban || 'no';
    update[`users/${uid}/platform`] = userJSON?.platform || 'register';
    update[`users/${uid}/gmtOffset`] = userJSON?.gmtOffset || offset || 0;
    update[`users/${uid}/profile_image`] = userJSON?.profile_image ||  "";
    update[`users/${uid}/user_id`] = userJSON?.user_id ||  "994479914";
    if(referredBy) update[`users/${uid}/referredBy`] = referredBy;
    if (source) update[`users/${uid}/sources/${source}`] = Date.now();
    if (utmParams) update[`users/${uid}/utmParams`] = utmParams || null;
    try{
      if (utmParams){
        let utmUpdates = {};
        const key = utmParams.gclid || utmParams.gbraid || utmParams.wbraid || utmParams.gclsrc || null;
        if (key){
          const utmKeys = Object.keys(utmParams);
          utmKeys.forEach((utmKey) => {
            utmUpdates[`googleAds/${key}/${utmKey}`] = utmParams[utmKey];
          });
          utmUpdates[`googleAds/${key}/updated_at`] = Date.now();
          utmUpdates[`googleAds/${key}/patientID`] = uid;

          if (source){
            utmUpdates[`googleAds/${key}/source`] = source;
          }
          if (Object.keys(utmUpdates).length > 0){
            database.ref().update(utmUpdates).then((res) => {
              console.log('setSocialInfo res', res?.data || res);
            }).catch((error) => {
              console.log('setSocialInfo error', error?.data || error);
            });
          }
        }
      }
    }
    catch(err){
        console.log("setSocialInfo err", err)
    }
    await database.ref().update(update);
    if (!userJSON?.country){
      getIP(async ip => {
        const fetchUserAddressPayload = {
          ip: ip || null,
          userID: uid
        }

        if (locationInfo){
            fetchUserAddressPayload.lat = locationInfo?.latitude || null;
            fetchUserAddressPayload.lng = locationInfo?.longitude || null;
        }
        console.log("fetchUserAddressPayload ", fetchUserAddressPayload)
        const res = await axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          url: `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_NORTH_EAST_URL}/mobileApp-fetchUserAddress`,
          data: JSON.stringify(fetchUserAddressPayload)
        })
          const { status } = res.data || {}
          const locationData = res?.data?.data || {}
          if(status === "success"){
            const { country, city, province, postcode, display_name } = locationData
            let updates = {};
            if(country) updates[`users/${uid}/country`] = country || null;
            if(city) updates[`users/${uid}/city`] = city || null;
            if(postcode) updates[`users/${uid}/postcode`] = postcode || null;
            if(postcode) updates[`users/${uid}/postalcode`] = postcode || null;
            if(province) updates[`users/${uid}/state`] = province || null;
            if(userJSON.ip || ip) updates[`users/${uid}/last_sign_at_ip`] = userJSON.ip || ip || null;
            if(userJSON.ip || ip) updates[`users/${uid}/current_sign_at_ip`] = userJSON.ip || ip || null;
            if(display_name) updates[`users/${uid}/complete_address`] = display_name || null;
            if (Object.keys(updates).length>0){
              await database.ref().update(updates)
            }

          }
        /* getLatLngWithIP(ip, userLocationData => {
          getAddressWithLatLng(userLocationData, async userLocationAddressData => {
            const {
              latitude,
              longitude,
              location,
            } = userLocationData || {}
            const {
              complete_address,
              country,
              country_code,
              city,
              county,
              postcode,
              state,
            } = userLocationAddressData || {}
            let updates = {};
            updates[`users/${uid}/latitude`] = latitude || null;
            updates[`users/${uid}/longitude`] = longitude || null;
            updates[`users/${uid}/complete_address`] = complete_address || null;
            updates[`users/${uid}/country`] =  country || null;
            updates[`users/${uid}/country_code`] = country_code || null;
            updates[`users/${uid}/city`] =  city || county || null;
            updates[`users/${uid}/postcode`] =  postcode || null;
            updates[`users/${uid}/state`] =  state || null;
            await database.ref().update(updates);
          });
        }); */
      });
    }
  }catch(err){
    console.log("setSocialInfo err", err)
  }
}

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const authenticate = (source, utmParams, referralParams) => (dispatch, getState) => {
    const existingSource = getState()?.source;
  if (source){
    dispatch(slice.actions.setSource(source))
  }
  if (utmParams){
    dispatch(slice.actions.setUtmParams(utmParams))
  }
  if (referralParams?.user_id){
      let currentEnv = process.env.REACT_APP_ENVIRONMENT;
      if (currentEnv === 'production'){
        currentEnv = 'prod'
      }
      const {environment, user_id} = referralParams || {}
      if(environment === currentEnv){
          dispatch(slice.actions.setReferredBy(user_id))
      }
  }
  auth.onAuthStateChanged(async (me) => {
    try{
        console.log("onAuthStateChanged me ", me)
      /* let emailVerified = me? me?.emailVerified: true;
      if(!emailVerified){
        const user = firebase.auth().currentUser;
        if(user){
          me.sendEmailVerification();
          setInterval(()=>{
            user.reload();
          }, 1000)
          emailVerified = user.emailVerified;
        }
      } */
      if (!me) {
        dispatch(slice.actions.resetState())
        console.log("!me ", !me)
        // dispatch(slice.actions.setChecked(true))
        return dispatch(
          slice.actions.setMe({
            loggedIn: false,
            checked: true,
            emailVerified: false,
            me: {},
          }),
        )
      }
      dispatch(slice.actions.setLoggedIn(true))


      if(me?.uid){
          setTimeout(()=>{
         const ydoWebTrackingSessionID = getCookie('ydoSessionID');
         if (ydoWebTrackingSessionID) {
           const trackingData = {
             sessionID: ydoWebTrackingSessionID,
             patientID: me?.uid,
             shouldUpdatePatient:true
           };

           fetch(`${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_NORTH_EAST_URL}/trackUserData`, {
             method: 'POST',
             headers: {
               'Content-Type': 'application/json',
             },
             body: JSON.stringify(trackingData),
           })
             .then((response) => {
               console.log('Data sent to cloud function:', response);
             })
             .catch((error) => {
               console.error('Error sending data:', error);
             });
         }
       },3000)

        if(userProfileRef) userProfileRef.off()
        userProfileRef = database.ref(`users/${me?.uid}`)
        userProfileRef.on('value', (user) => {

            const userJSON = user.exists()?user.toJSON():{}
            const {sources = {}} = userJSON
            if(existingSource){
                if (!sources[existingSource]){
                    const updates = {};
                    updates[`users/${me?.uid}/sources/${existingSource}`] = Date.now();
                    database.ref().update(updates).then(()=>{}).catch((error)=>{console.log("error", error)});
                }
            }
            if (source){
                if (!sources[source]){
                    const updates = {};
                    updates[`users/${me?.uid}/sources/${source}`] = Date.now();
                    database.ref().update(updates).then(()=>{}).catch((error)=>{console.log("error", error)});
                }
            }

          // console.log("user.val() ", user.val())
          const payload = {
            loggedIn: !!me,// ?.emailVerified,// || !!thirdPartyData,
            me: user.exists()
              ? {
                id: me?.uid,
                emailVerified: me?.emailVerified,
                email: me?.email, ...userJSON,
              }
              : {
                id: me?.uid,
                emailVerified: me?.emailVerified,
                email: me?.email,
              },
            checked: true,
          }
          // console.log("setMe payload ", payload)
          // fetchSqlData(me?.email, dispatch, payload)
          if (!user.exists()) {
            dispatch(slice.actions.setNewUser(true))
          } else {
            dispatch(slice.actions.setNewUser(false))
          }
          return dispatch(slice.actions.setMe(payload))

        })
      }
      // login

    }
    catch(error){
      console.log("authenticate error", error)
    }


  })
}

export const signup = ({ fullName, email, password }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // create user
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      )

      // send confirmation email
      await user.sendEmailVerification()

      /* // store user info in firestore
       await firestore.collection('users').doc(user.uid).set({
         fullName,
         email,
       }) */

      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

export const login = ({ email, password }) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password)
      if (!user) reject(new Error('Failed to login. please try it again later'))
      if (!user.emailVerified) await user.sendEmailVerification()
      dispatch(authenticate())
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

export const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      auth.signOut().then(()=>{
        dispatch(slice.actions.resetState())
        const newSessionKey = database.ref('/sessions/').push().key || null
        dispatch(slice.actions.setSessionKey(newSessionKey))
        if(newSessionKey)database.ref(`/sessions/${newSessionKey}/timestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});
        if(newSessionKey)database.ref(`/sessions/${newSessionKey}/updatedTimestamp/`).set(Date.now()).then(()=>{}).catch((error)=>{console.log("error", error)});

        dispatch(
          slice.actions.setMe({
            checked: true,
            loggedIn: false,
            me: {},
          }),
        )
        resolve()
      }).catch(error=>{
        console.log("logout err ", error)
        reject(error)
      })


    } catch (err) {
      console.log("logout err ", err)
      reject(err)
    }
  })

export const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

export const setSource = (source) => (dispatch) => dispatch(
  slice.actions.setSource(source),
)

export const setUserIssue = (issue) => dispatch => dispatch(
  slice.actions.setUserIssue(issue)
)

export const setLocationInfo = (coords) => dispatch => dispatch(
    slice.actions.setLocationInfo(coords)
)

export const setStoreUsersConsent = (consent) => dispatch => dispatch(
    slice.actions.setStoreUsersConsent(consent)
)

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  setUserIssue,
  authenticate,
  signup,
  login,
  logout,
  resetPassword,
  setSocialInfo,
  setSource,
  setLocationInfo,
    setStoreUsersConsent
}

export default slice.reducer
